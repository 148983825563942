import type { NoCommentMeetingParticipant } from "@sgdf/client";
import { sorterMap } from "~/core/utils/participant-sorter";

export const useSortParticipants = ({
  participants,
}: {
  participants: Ref<NoCommentMeetingParticipant[]> | Ref<undefined>;
}) => {
  const sortStore = useSortStore();
  const route = useRoute();

  const { isSortAsc, sortField } = storeToRefs(sortStore);

  onMounted(async () => {
    navigateTo({
      query: {
        ...route.query,
        sortField: sortField.value,
        isSortAsc: isSortAsc.value ? "true" : "false",
      },
    });
  });


  onUnmounted(() => {
    if (!Array.isArray(route.query.sortField)) {
      sortStore.setSortField(route.query.sortField ?? "firstname");
    }
    if (!Array.isArray(route.query.isSortAsc)) {
      sortStore.setSortAsc(route.query.isSortAsc === "true");
    }
  });


  const interns = computed(() => {
    const interns = [...(participants?.value ?? [])];
    const { sortField, isSortAsc } = route.query;
    if (
      Array.isArray(sortField) ||
      Array.isArray(isSortAsc) ||
      typeof sortField !== "string" ||
      typeof isSortAsc !== "string"
    ) {
      return participants?.value ?? []
    }

    if (sortField === "shuffle") {
      return interns
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
    }

    const sorter = sorterMap[sortField ?? "firstname"]?.[isSortAsc];

    if (sorter) {
      return interns.sort(sorter);
    }

    return participants?.value ?? [];
  });


  return {
    interns,
    route,
  };
}
